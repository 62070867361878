import { useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import logo from "../static/img/logo.png";

import cam from "../static/svg/cam.svg";
//

const NavBar = (props) => {
	useEffect(() => {
		const menuBtn = document.querySelector(".menu-btn");
		const menuItems = document.querySelector(".menu-items");
		const expandBtn = document.querySelectorAll(".expand-btn");
		const menuItem = document.querySelectorAll(".menu-item");
		const dropDownItem = document.querySelectorAll(".dropdown-item");

		const toggleNav = () => {
			menuBtn.classList.toggle("open");
			menuItems.classList.toggle("open");
		};

		// humburger toggle
		menuBtn.addEventListener("click", () => {
			toggleNav();
		});

		// mobile menu expand
		expandBtn.forEach((btn) => {
			btn.addEventListener("click", () => {
				btn.classList.toggle("open");
			});
		});

		// menu item click
		menuItem.forEach((item) => {
			item.addEventListener("click", () => {
				if (!item.classList.contains("services-item")) {
					toggleNav();
				}
			});
		});

		// dropdown
		dropDownItem.forEach((item) => {
			item.addEventListener("click", () => {
				toggleNav();
			});
		});
	});

	return (
		<header className="header">
			<NavLink to="/" className="logo">
				<img
					src={logo}
					className="site-logo"
					style={{ maxHeight: "8vh" }}
					alt="The Colony Security Logo"
				/>
				<span className="site-name">The Colony Security Services Ltd</span>
			</NavLink>
			<div className="menu-btn">
				<div className="menu-btn__lines"></div>
			</div>
			<ul className="menu-items">
				<li>
					<NavLink
						activeClassName="active-nav"
						to="/"
						exact
						className="menu-item expand-btn">
						HOME
					</NavLink>
				</li>
				<li>
					<NavLink
						activeClassName="active-nav"
						to="/about"
						exact
						className="menu-item expand-btn">
						ABOUT
					</NavLink>
				</li>
				<li>
					<a href="#" className="menu-item services-item expand-btn">
						SERVICES
					</a>
					<div className="mega-menu expandable">
						<div className="content">
							<div className="col">
								<section>
									<ul className="mega-links">
										<li className="d-flex">
											{/* <img src={cam} style={{ maxHeight: "10vh" }} alt="" /> */}
											<NavLink
												activeClassName="active-nav"
												exact
												to="/services/mining-security"
												className="dropdown-item">
												Mining Security
											</NavLink>
										</li>
										<li className="d-flex">
											{/* <img src={cam} style={{ maxHeight: "10vh" }} alt="" /> */}

											<NavLink
												activeClassName="active-nav"
												exact
												to="/services/audio-visual"
												className="dropdown-item">
												Audio Visual Surveillance Services
											</NavLink>
										</li>
										<li className="d-flex">
											{/* <img src={cam} style={{ maxHeight: "10vh" }} alt="" /> */}

											<NavLink
												activeClassName="active-nav"
												exact
												to="/services/corporate-security-training"
												className="dropdown-item">
												Corporate Security & Awareness Training
											</NavLink>
										</li>
									</ul>
								</section>
							</div>
							<div className="col">
								<section>
									<ul className="mega-links">
										<li className="d-flex">
											{/* <img src={cam} style={{ maxHeight: "10vh" }} alt="" /> */}

											<NavLink
												activeClassName="active-nav"
												exact
												to="/services/it-solutions"
												className="dropdown-item">
												I.T. Solutions
											</NavLink>
										</li>
										<li className="d-flex">
											{/* <img src={cam} style={{ maxHeight: "10vh" }} alt="" /> */}

											<NavLink
												activeClassName="active-nav"
												exact
												to="/services/man-guarding"
												className="dropdown-item">
												Man Guarding
											</NavLink>
										</li>
										<li className="d-flex">
											{/* <img src={cam} style={{ maxHeight: "10vh" }} alt="" /> */}

											<NavLink
												activeClassName="active-nav"
												exact
												to="/services/security-assessment"
												className="dropdown-item">
												Security and Risk Assessment
											</NavLink>
										</li>
									</ul>
								</section>
							</div>
						</div>
					</div>
				</li>

				<li>
					<NavLink
						activeClassName="active-nav"
						to="/contact"
						exact
						className="menu-item">
						CONTACT
					</NavLink>
				</li>
			</ul>
		</header>
	);
};

export default NavBar;
